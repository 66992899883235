
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background: linear-gradient(to bottom right, #5d232e, #eb88a3);
  }
  
  /* Header styles */
  
  .logo-btn-image{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    }
    .logo-btn-image img{
      margin-bottom: 0px;
    }
    .logo-btn-image a{
      margin-bottom: -4px;
    }
  header {
    background-color: #f8f8f8;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h1 {
    color: #333;
    font-size: 36px;
    margin: 0;
  }
  
  .header-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
  }
  
  .header-image img {
    max-width: 100%;
    max-height: 591px;
    object-fit: contain;
    border-radius: 10px;
  }
  /* Main section styles */
  .main-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px;
  }
  
  .left-section {
    flex: 4;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-right: 20px;
    margin-left:25px;
    min-height: 480px;
  }
  
  .right-section {
    flex: 1;
    padding: 20px;
    padding-bottom: 0px;
    background-color: #f8f8f8;
    border-radius: 10px;
    min-height: 444px;
  }
  
  /* Main styles */
  main {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-left: 55px;
    padding-right: 80px;
  }
  
  section {
    margin-bottom: 40px;
  }
  
  h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    color: #666;
    line-height: 1.5;
  }
  
  /* Bottom section styles */
  .bottom-section {
    text-align: center;
    margin: 40px 0;
    margin-top: 0px;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .bottom-section h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .bottom-section p {
    color: #666;
    line-height: 1.5;
  }
  
  footer{
    text-align: right;
    padding-right: 20pt;
  }
  footer p{
    color: #5d232e;
  }
  
  /* Info gallery styles */
  .info-gallery {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 60px;
    margin-top: 0px;
  }
  
  .info-card {
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    min-height: 630px;
    margin-right: 10px;
    margin-left:8px;
  }
  
  .info-card:hover {
    transform: translateY(-5px);
  }
  
  
  .info-card h3 {
    margin-top: 10px;
    font-size: 20px;
  }
  
  .info-card p {
    margin-top: 10px;
  }
  
  
  .logo-image img {
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  /* Styles for mobile devices */
  @media (max-width: 767px) 
  
  {
  
    main {
      max-width: 100%;
      margin: 0 auto;
      padding: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .main-container, .info-gallery{
      flex-wrap: wrap;
      margin:0px;
    }
    .main-container{
      margin: 0px;
    }
    .left-section, .right-section{
      flex: 100%;
      margin-left:0px;
      margin-right: 0px;
      margin-bottom: 10px;
  }
  .info-card {
    flex: 100%;
    margin-left:20px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .fb-page{
    width: 100%;
  }
  .bottom-section {
    text-align: center;
    margin: 40px 0;
    margin-top: 0px;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .logo-btn-image img {
    margin-bottom: 0px;
    width: 150px;
  }
  
  }
  
  /* Styles for tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    /* Your CSS code for tablets */
  }
  